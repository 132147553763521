<template>
     <div class="container-fluid" style="height: 100vh; overflow: hidden;">
        <div class="row">
            <div class="d-none d-lg-block col-lg-8 p-0">

                <img src="@/assets/images/back.webp" alt="" style="width: 100%; height: 108vh;">

            </div>
            <div class="col-12 col-lg-4">

                <div class="row justify-content-center align-items-center position-relative" style="height: 100vh;">
                    <div class="col-8">
                        <h3 class="text-center m-4">Viki AI</h3>
                        <form>
                            <div class="mb-3">
                                <input type="text" placeholder="სახელი" class="form-control" v-model="fname">
                            </div>
                            <div class="mb-3">
                                <input type="text" placeholder="გვარი" class="form-control" v-model="lname">
                            </div>
                            <div class="mb-3">
                                <input type="email" placeholder="მეილი" class="form-control" v-model="email">
                            </div>
                            <div class="mb-3">
                                <input type="password" placeholder="პაროლი" class="form-control" v-model="password">
                            </div>
                            <div class="mb-3">
                                <input type="password" placeholder="გაიმეორეთ პაროლი" class="form-control" v-model="confirmPassword">
                            </div>
                            <div class="mb-3">
                                
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="userType" v-model="isOrganization" value="0">
                                    <label class="form-check-label" for="inlineRadio1">ფიზიკური პირი</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="userType"  v-model="isOrganization" value="1">
                                    <label class="form-check-label" for="inlineRadio2">იურიდიული პირი</label>
                                  </div>
                                
                            </div>
                          
                            <div class="mb-3" v-if="isOrganization==1">
                                <input type="text" class="form-control" placeholder="ორგანიზაციის სახელი" v-model="orgName">
                            </div>
                            <div class="mb-3" v-if="isOrganization==1" >
                                <input type="text" class="form-control" placeholder="სარეგისტრაციო კოდი" v-model="orgRegCode">
                            </div>

                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-8">
                                        <input type="number" placeholder="ერთჯერადი კოდი" class="form-control" v-model="oneTimeCode">
                                    </div>
                                    <div class="col-4 d-grid">
                                        <button v-if="!getOneTimeCodeData" type="button" class="btn btn-outline-success" :disabled="!fname || !lname || !email || !password || !confirmPassword 
                                || (isOrganization==1 && (!orgName || !orgRegCode))" @click="getOneTimeCode()">მიღება</button>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="d-grid">
                                <button v-if="!saveUserData" type="button":disabled="!fname || !lname || !email || !password || !confirmPassword 
                                || (isOrganization==1 && (!orgName || !orgRegCode)) || !oneTimeCode" class="btn btn-outline-warning" @click="saveUser()">რეგისტრაცია</button>
                            </div>
                        </form>
                         <p style="font-size: 10px;" class="text-center m-2">თუ უკვე დარეგისტრირებული ხარ, გაიარე <router-link to="/signIn">ავტორიზაცია</router-link></p>   
                    </div>
                    <div class="position-absolute bottom-0 text-center mb-3">&copy; ყველა უფლება დაცულია. 2024. <a href="https://viki-ai.ge">viki-ai.ge</a></div>
                </div>

               
            </div>
        </div>
    </div>
</template>
<script>
import HttpRequests from '../httpRequests/httpRequests'

const httpRequests=new HttpRequests()

export default{
    data(){
        return{
            isOrganization:0,
            fname:null,
            lname:null,
            email:null,
            password:null,
            confirmPassword:null,
            orgName:null,
            orgRegCode:null,
            oneTimeCode:null,
            getOneTimeCodeData:false,
            saveUserData:false
        }
    },
    watch:{
        isOrganization(val){

            if(val==0){
                this.orgName=null
                this.orgRegCode=null
            }

        }
    },
    methods:{

        getOneTimeCode(){

            this.getOneTimeCodeData=true

            httpRequests.getOneTimeCode(this.email).then(response=>{

                this.$swal({
                        title: 'ძვირფასო მომხმარებელო',
                        text: 'თქვენ მიერ მითითებულ მეილზე გამოიგზავნა ერთჯერადი კოდი. გთხოვთ გადაამოწმოთ მეილი. ერთჯერადი კოდი ვალიდურია 2 წუთის განმავლობაში!',
                        icon: 'warning',
                        confirmButtonText: 'კარგი'
                        });

                        this.getOneTimeCodeData=false

            })

        },
        saveUser(){

                this.saveUserData=true

            httpRequests.saveUser(this.fname,this.lname,this.email,this.password,this.confirmPassword,this.isOrganization,
            this.orgName,this.orgRegCode,this.oneTimeCode).then(response=>{


                this.$swal({
                        title: 'გილოცავთ!',
                        text: this.fname+', თქვენ წარმატებით გაიარეთ რეგისტრაცია!',
                        icon: 'success',
                        confirmButtonText: 'კარგი', 

                        }).then(response=>{
                            if(response.isConfirmed){
                                location.reload()
                            }
                        });

                       
                    this.saveUserData=false

            }).catch(err=>{

                this.$swal({
                        title: 'შეცდომა',
                        text: err.response.data.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });

                        this.saveUserData=false
            })
        }

    }

}

</script>