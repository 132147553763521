import { createRouter, createWebHashHistory } from 'vue-router'
import MainComponent from '@/components/MainComponent.vue'
import Home from '@/pages/Home.vue'
import Api from '@/pages/Api.vue'
import Billing from '@/pages/Billing.vue'
import TryViki from '@/pages/TryViki.vue'
import Login from '@/pages/Login.vue'
import Registration from '@/pages/Registration.vue'

import SpeechToText from '@/pages/SpeechToText.vue'
import TextToSpeech from '@/pages/TextToSpeech.vue'
import PDFToSpeech from '@/pages/PDFToSpeech.vue'

 

const routes = [
  {
    path: '/',
    name: 'MainComponent',
    component: MainComponent,
    children:[
      {

        path: '/',
        name: 'Home',
        component: Home,
        meta:{
          requiresAuth:true
        }


      },
      {

        path: '/api',
        name: 'Api',
        component: Api,
        meta:{
          requiresAuth:true
        }


      }
      ,
      {

        path: '/billing',
        name: 'Billing',
        component: Billing,
        meta:{
          requiresAuth:true
        }


      }
      ,
      {

        path: '/tryViki',
        name: 'Try Viki',
        component: TryViki,
        meta:{
          requiresAuth:true
        }


      }
      ,
      {

        path: '/speechToText',
        name: 'speechToText',
        component: SpeechToText,
        meta:{
          requiresAuth:true
        }


      }
      ,
      {

        path: '/textToSpeech',
        name: 'textToSpeech',
        component: TextToSpeech,
        meta:{
          requiresAuth:true
        }


      }
      ,
      {

        path: '/pdftospeech',
        name: 'pdftospeech',
        component: PDFToSpeech,
        meta:{
          requiresAuth:true
        }


      }

       
    ]
  },
  ,
      {

        path: '/signIn',
        name: 'signIn',
        component: Login,
       


      }
      ,
      {

        path: '/signUp',
        name: 'signUp',
        component: Registration,
       


      }

       

       
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to,from,next)=>{

  if(to.matched.some(record=>record.meta.requiresAuth)){

      if(localStorage.getItem('token')){
        next()
      }else{
        next('/signIn')
      }


   
  }else{
    next()
  }

})


export default router
