<template>
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-8">
            
            <div class="row">
                <div class="row">
            <!-- Card 1 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 180px !important;">
                        <h5 class="card-title">ნაბიჯი 1</h5>
                        <p class="card-text">აირჩიე ენა</p>
                        

                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio1" value="ka">
                        <label class="form-check-label" for="inlineRadio1">ქართ.</label>
                        </div>
                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio2" value="en">
                        <label class="form-check-label" for="inlineRadio2">ENG.</label>
                        </div>



                    </div>
                </div>
            </div>
            <!-- Card 2 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 180px !important;">
                        <h5 class="card-title">ნაბიჯი 2</h5>
                        <p class="card-text">ატვირთე PDF-ები შესაბამისად შერჩეულ ენაზე</p>
                       
                    </div>
                </div>
            </div>
            <!-- Card 3 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 180px !important;">
                        <h5 class="card-title">ნაბიჯი 3</h5>
                        <p class="card-text">სატესტოდ დაუსვი კითხვა ვიკის , მისწერე ან გაუგაზავნე ხმოვანი კითხვა შესაბამისად შერჩეულ ენაზე, მიიღე პასუხი და გაახმოვანე</p>
                        
                    </div>
                </div>
            </div>
        </div>
            </div>


            <div class="d-grid">
                <button class="btn btn-outline-danger" @click="clickOnGetPdf()">PDF-ების ატვირთვა</button>
                <input type="file" class="d-none" id="getPdf"  @change="getPdf">
            </div>
            <h5 class="text-center m-3" v-if="uploadStatus">{{ uploadStatus }}</h5>
            <div>
                <p>სატესტოდ დაუსვი კითხვა ვიკის</p>

                <div class="row">
                    <div class="col-11">
                        <input type="text" class="form-control" v-model="question"/>
                      
                    </div>
                    <div class="col-1">
                        <button v-if="!isRecording" class="btn btn-outline-secondary" type="button" @click="startRecording()">
                            <i class="bi bi-mic-mute-fill"></i>
                        </button>
                        
                            <button v-else class="btn btn-outline-secondary" type="button" @click="stopRecording()">
                                <i class="bi bi-mic-fill"></i>
                            </button>
                       
                         
                      

                    </div>
                </div>
               

              

                <div class="row justify-content-around mt-3">
                    <div class="col-12 col-md-4 m-2">
                        <button class="btn btn-outline-warning" :disabled="!question" type="button" @click="sendQuestion()">კითხვის დასმა</button>
            
                    </div>
                   
                </div>

                <div class="row align-items-center"  v-if="answer">
                    <div class="col-10">
                        <textarea class="form-control mt-5" style="min-height: 400px;">{{ answer }}</textarea>
                    </div>
                    <div class="col-2">
                        <div v-for="voice in voices" :key="voice.vocieID" class="mb-2">
                            <button type="button" class="btn btn-outline-secondary w-100" @click="speechIt(voice.voiceID)">
                                <i :class="!translatingToText[voice.voiceID]?'bi bi-headset':'bi bi-hourglass-top'"></i>{{ voice.voice }}
                                
                            </button>
                        </div>
                    </div>

                    <audio class="d-none" ref="audioPlayer" controls></audio>

                </div>

                
               
            </div>

        </div>
    </div>
</template>
<script>
    import HttpRequests from '../httpRequests/httpRequests'
    const httpRequests= new HttpRequests()
    const CryptoJS=require('crypto-js')
    import axios from 'axios'


    export default{
        data(){
            return{
                 uploadStatus:null,
                 question:null,
                 answer:null,
                isRecording: false,
                audioContext: null,
                mediaStream: null,
                mediaRecorder: null,
                audioChunks: [],
                recordedBlob: null,
                chosenAudioLang:'ka',
                voices:[],
                translatingToText:{}
            }
        },

        mounted(){

            this.generateHeader().then(()=>{

          
            httpRequests.getVoices(this.chosenAudioLang).then(response=>{
                    this.voices=response.data
            })

        })

        },
        watch:{
            chosenAudioLang(val){
                this.generateHeader().then(()=>{

               
                httpRequests.getVoices(val).then(response=>{
                    this.voices=response.data
            })
        })
            }
        },
        methods:{

            async  generateHeader(){
             
             return   httpRequests.customMiddlewareTokens().then(response=>{
    
                  
                  axios.defaults.headers.common['X-Trial-Header']=CryptoJS.MD5('YH36uiJvxBfixgUfwuKeyVF2CzEScE'+response.data).toString() 
                    
                      })
                
             
                  },

            clickOnGetPdf(){
                document.getElementById('getPdf').click()
            },
            getPdf(e){
                this.generateHeader().then(()=>{
             let formData=new FormData()
             
               
                formData.append('pdfFiles',e.target.files[0])
             
             this.uploadStatus='იტვირთება, გთხოვთ დაელოდოთ'
             httpRequests.uploadPdf(formData).then(response=>{
                
                if(response.data.status=='IS.UPLOADED'){
                

                    this.$swal({
                        title: 'აიტვირთა!',
                        text:  'სწავლების დაწყება/დასრულების თაობაზე მიიღებთ შეტყობინებას თქვენს მეილზე.',
                        icon: 'success',
                        confirmButtonText: 'კარგი', 

                        }).then(response=>{
                            if(response.isConfirmed){
                                location.reload()
                            }
                        });




                  
                   
                }

             }).catch(err=>{

                this.$swal({
                        title: 'შეცდომა!',
                        text: err.response.data.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });

                 


             })

            })

            },
           
            sendQuestion(){
                this.generateHeader().then(()=>{

                
                this.answer='ვიკი ფიქრობს...'
                httpRequests.sendQuestion(this.question,this.chosenAudioLang).then(response=>{
                    this.answer=response.data
                }).catch(err=>{

                    this.$swal({
                        title: 'შეცდომა!',
                        text: err.response.data.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });

                })
            })
            },
           
            



            async startRecording() {
             
      this.isRecording = true;
      this.question='ვიკი გისმენს...'

      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const source = this.audioContext.createMediaStreamSource(this.mediaStream);

      // Create a mono merger node
      const merger = this.audioContext.createChannelMerger(1);
      source.connect(merger, 0, 0);

      const monoStream = this.audioContext.createMediaStreamDestination();
      merger.connect(monoStream);

      this.mediaRecorder = new MediaRecorder(monoStream.stream);

      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        const wavBuffer = this.encodeWAV(audioBuffer);
        this.recordedBlob = new Blob([wavBuffer], { type: 'audio/wav' });

        // Do something with the binary data
        const reader = new FileReader();
        reader.readAsArrayBuffer(this.recordedBlob);
        reader.onloadend = () => {
          const arrayBuffer = reader.result;
          // Here you can use arrayBuffer which is a binary representation of the .wav file
               
          const base64String = this.arrayBufferToBase64(arrayBuffer);

          this.generateHeader().then(()=>{
                    
              
          
            httpRequests.convertSpeechToText(base64String,this.chosenAudioLang).then(response=>{
                this.question=response.data
            })
        })
           
        };
      };

      this.audioChunks = [];
      this.mediaRecorder.start();
    },
    stopRecording() {

       
      this.isRecording = false;
      this.question='ვიკი აანალიზებს, გთხოვ დაელოდო...'
      this.mediaRecorder.stop();
      this.mediaStream.getTracks().forEach(track => track.stop());
    },
    encodeWAV(audioBuffer) {
      const numberOfChannels = 1;
      const sampleRate = audioBuffer.sampleRate;
      const format = 1; // PCM
      const bitDepth = 16;

      const result = this.interleave(audioBuffer.getChannelData(0), numberOfChannels);

      const byteRate = sampleRate * numberOfChannels * (bitDepth / 8);
      const blockAlign = numberOfChannels * (bitDepth / 8);

      const buffer = new ArrayBuffer(44 + result.length * 2);
      const view = new DataView(buffer);

      /* RIFF identifier */
      this.writeString(view, 0, 'RIFF');
      /* file length */
      view.setUint32(4, 36 + result.length * 2, true);
      /* RIFF type */
      this.writeString(view, 8, 'WAVE');
      /* format chunk identifier */
      this.writeString(view, 12, 'fmt ');
      /* format chunk length */
      view.setUint32(16, 16, true);
      /* sample format (raw) */
      view.setUint16(20, format, true);
      /* channel count */
      view.setUint16(22, numberOfChannels, true);
      /* sample rate */
      view.setUint32(24, sampleRate, true);
      /* byte rate (sample rate * block align) */
      view.setUint32(28, byteRate, true);
      /* block align (channel count * bytes per sample) */
      view.setUint16(32, blockAlign, true);
      /* bits per sample */
      view.setUint16(34, bitDepth, true);
      /* data chunk identifier */
      this.writeString(view, 36, 'data');
      /* data chunk length */
      view.setUint32(40, result.length * 2, true);

      let offset = 44;
      for (let i = 0; i < result.length; i++, offset += 2) {
        view.setInt16(offset, result[i] * 0x7FFF, true);
      }

      return buffer;
    },
    writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    interleave(input, numberOfChannels) {
      const length = input.length * numberOfChannels;
      const result = new Float32Array(length);

      for (let i = 0; i < input.length; i++) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
          result[i * numberOfChannels + channel] = input[i];
        }
      }

      return result;
    },
  
    arrayBufferToBase64(buffer) {
      const binary = [];
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary.push(String.fromCharCode(bytes[i]));
      }
      return window.btoa(binary.join(''));
    },


    speechIt(vocieID){

        this.generateHeader().then(()=>{

       
        this.translatingToText[vocieID]=true

        httpRequests.speechIt(vocieID,this.chosenAudioLang,this.answer).then(response=>{

            this.translatingToText={}

            const blob = new Blob([response.data], { type: 'audio/wav' });
            const url = URL.createObjectURL(blob);
            this.$refs.audioPlayer.src = url;
            this.$refs.audioPlayer.play();
 
        }).catch(err=>{
            this.translatingToText={}
            const blob = new Blob([err.response.data], { type: 'application/json' });

            blob.text().then((text) => {
                const jsonData = JSON.parse(text);
               

                this.$swal({
                        title: 'შეცდომა!',
                        text: jsonData.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });
            });
 
           
        })
    })
    }




        }

    }

</script>