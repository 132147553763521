<template>
  <router-view/>
</template>
<script>
import axios from 'axios'
import HttpRequests from './httpRequests/httpRequests'
const httpRequests= new HttpRequests()
 

export default{

  mounted(){
      
      axios.defaults.baseURL='https://api.viki-ai.ge/public/api'
      if(localStorage.getItem('token')){
      axios.defaults.headers.common.Authorization='Bearer '+localStorage.getItem('token')

      axios.defaults.withCredentials=true
      httpRequests.getInfoOnRefresh().then(response=>{
        this.$store.dispatch('setUserInfo',response.data)
      })
    }

  }

}

</script>

 
