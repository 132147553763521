<template>
    <h1 class="text-center">API დოკუმენტაცია</h1>

    <p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong>თქვენი apiKey: <span class="text-danger">{{ this.$store.state.userInfo?.apiKey }}</span></strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong>თქვენი secret: <span class="text-danger">{{ this.$store.state.userInfo?.secret }}</span></strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><em>ყურადღება! არავის გაუზიაროთ ზემოთ მოცემული&nbsp;</em><strong><em>apiKey &nbsp;&nbsp;</em></strong><em>და <strong>secret</strong></em></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">იმისათვის რომ გამოიყენოთ API End-points თქვენ ვალდებული ხართ გაიაროთ ავტორიზაცია.&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: red;">მნიშვნელოვანია: apiKey და secret უნდა არსებობდეს &nbsp;ყველა End-point-ის Header-ში</span></strong></p>
<div style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">
    <ul >
        <li style="font-size: 11pt;"><strong>ავტორიზაციის და&nbsp;</strong><strong>Access Token-</strong><strong>ის მისაღებად:</strong></li>
    </ul>
</div>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">End-point: <u><a href="https://api.viki-ai.ge/public/api/v1/getAccessToken" style="color: rgb(13, 110, 253);">https://api.viki-ai.ge/public/api/v1/getAccessToken</a></u></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Method: POST</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Request Body: email , password (რომლითაც დარეგისტრირდით)</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Response: ინფორმაცია მომხმარებლის შესახებ , რომელშიც ერთ-ერთი გასაღები არის: <strong>token.</strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong>Error Response: 422 &ndash; Invalid Credentials&nbsp;</strong>ასეთის არსებობის შემთხვევაში&nbsp;</p>
<div style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">
    <ul >
        <li style="font-size: 11pt;"><strong>PDF</strong><strong>-ების ატვირთვა და სწავლება</strong></li>
    </ul>
</div>
 <p>იმისათვის რომ ვიკის აუტვირთოთ სასწავლო მასალა, გადადით <router-link to="/tryViki">აქ</router-link> და ატვირთეთ თქვენი PDF ფაილები.</p>
<div style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">
    <ul>
        <li style="font-size: 11pt;"><strong>ვიკისთვის კითხვის დასმა და პასუხის მიღბა</strong></li>
    </ul>
</div>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">End-point: <u><a href="https://api.viki-ai.ge/public/api/v1/getAccessToken" style="color: rgb(13, 110, 253);">https://api.viki-ai.ge/public/api/v1/sendQuestion</a></u></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Method: POST</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Authorization: Bearer Token</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Request Body: question , lang (ka&nbsp;ან&nbsp;en)</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Response : response (პასუხი)</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Error Response : <strong>422 - Not enough Tokens&nbsp;</strong>ასეთის არსებობის შემთხვევაში. ამ შემთხვევაში გთხოვთ გადახვიდეთ ბილინგში ტოკენების შესაძენად.</p>
<div style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">
    <ul>
        <li style="font-size: 11pt;"><strong>აუდიოს ტექსტად გარდაქმნა</strong></li>
    </ul>
</div>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong>&nbsp;</strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">End-point: <u><a href="https://api.viki-ai.ge/public/api/v1/convertSpeechToText%0d" style="color: rgb(13, 110, 253);">https://api.viki-ai.ge/public/api/v1/convertSpeechToText</a></u></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Method: POST</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Authorization: Bearer Token</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Request Body: base64String , lang (ka&nbsp;ან&nbsp;en)</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">ამ შემთხვევაში პარამეტრი base64String არის .wav ფაილის დაკონვერტირებული arrayBuffer-ი. გაითვალისწინეთ რომ .wav ფაილი უნდა იყოს აუცილებლად <strong>MONO.</strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">ქვემოთ მოყვანილია VUE.JS ფრეიმვორკზე შექმნილი &nbsp;მაგალითი , რომელიც იღებს arrayBuffer-ს , გარდაქმნის MONO-ში და შემდგომ აკონვერტირებს base64-ში და ამზადებს end-point-ზე გასაგზავნათ.</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;template&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;button v-if=&quot;!isRecording&quot; class=&quot;btn btn-outline-secondary&quot; type=&quot;button&quot; @click=&quot;startRecording()&quot;&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &lt;i class=&quot;bi bi-mic-mute-fill&quot;&gt;&lt;/i&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &lt;/button&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &lt;button v-else class=&quot;btn btn-outline-secondary&quot; type=&quot;button&quot; @click=&quot;stopRecording()&quot;&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &lt;i class=&quot;bi bi-mic-fill&quot;&gt;&lt;/i&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &lt;/button&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;/template</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;script&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;export default{</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; data(){</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; return{&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; isRecording: false,</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; audioContext: null,</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; mediaStream: null,</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; mediaRecorder: null,</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; audioChunks: [],</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; recordedBlob: null,</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; chosenAudioLang:&apos;ka&apos;,</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; methods:{<br>&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; async startRecording() {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.isRecording = true;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.audioContext = new (window.AudioContext || window.webkitAudioContext)();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const source = this.audioContext.createMediaStreamSource(this.mediaStream);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; // Create a mono merger node</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const merger = this.audioContext.createChannelMerger(1);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; source.connect(merger, 0, 0);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const monoStream = this.audioContext.createMediaStreamDestination();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; merger.connect(monoStream);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaRecorder = new MediaRecorder(monoStream.stream);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaRecorder.ondataavailable = event =&gt; {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; this.audioChunks.push(event.data);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; };</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaRecorder.onstop = async () =&gt; {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; const audioBlob = new Blob(this.audioChunks, { type: &apos;audio/webm&apos; });</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; const arrayBuffer = await audioBlob.arrayBuffer();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; const wavBuffer = this.encodeWAV(audioBuffer);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; this.recordedBlob = new Blob([wavBuffer], { type: &apos;audio/wav&apos; });</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; // Do something with the binary data</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; const reader = new FileReader();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; reader.readAsArrayBuffer(this.recordedBlob);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; reader.onloadend = () =&gt; {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; const arrayBuffer = reader.result;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; // Here you can use arrayBuffer which is a binary representation of the .wav file</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; const base64String = this.arrayBufferToBase64(arrayBuffer);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; console.log(base64String,this.chosenAudioLang)</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong><span style="color: rgb(192, 0, 0);font-size: 19px;">//</span></strong><strong><span style="color: rgb(192, 0, 0);font-size: 19px;">მზა პარამეტრები&nbsp;</span></strong><strong><span style="color: rgb(192, 0, 0);font-size: 19px;">end-point-</span></strong><strong><span style="color: rgb(192, 0, 0);font-size: 19px;">ზე გასაგზავნათ</span></strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; };</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; };</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.audioChunks = [];</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaRecorder.start();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; stopRecording() {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.isRecording = false;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaRecorder.stop();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.mediaStream.getTracks().forEach(track =&gt; track.stop());</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; encodeWAV(audioBuffer) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const numberOfChannels = 1;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const sampleRate = audioBuffer.sampleRate;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const format = 1; // PCM</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const bitDepth = 16;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const result = this.interleave(audioBuffer.getChannelData(0), numberOfChannels);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const byteRate = sampleRate * numberOfChannels * (bitDepth / 8);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const blockAlign = numberOfChannels * (bitDepth / 8);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const buffer = new ArrayBuffer(44 + result.length * 2);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const view = new DataView(buffer);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* RIFF identifier */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.writeString(view, 0, &apos;RIFF&apos;);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* file length */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint32(4, 36 + result.length * 2, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* RIFF type */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.writeString(view, 8, &apos;WAVE&apos;);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* format chunk identifier */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.writeString(view, 12, &apos;fmt &apos;);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* format chunk length */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint32(16, 16, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* sample format (raw) */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint16(20, format, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* channel count */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint16(22, numberOfChannels, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* sample rate */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint32(24, sampleRate, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* byte rate (sample rate * block align) */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint32(28, byteRate, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* block align (channel count * bytes per sample) */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint16(32, blockAlign, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* bits per sample */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint16(34, bitDepth, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* data chunk identifier */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; this.writeString(view, 36, &apos;data&apos;);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; /* data chunk length */</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; view.setUint32(40, result.length * 2, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; let offset = 44;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; for (let i = 0; i &lt; result.length; i++, offset += 2) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; view.setInt16(offset, result[i] * 0x7FFF, true);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; return buffer;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; writeString(view, offset, string) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; for (let i = 0; i &lt; string.length; i++) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; view.setUint8(offset + i, string.charCodeAt(i));</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; interleave(input, numberOfChannels) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const length = input.length * numberOfChannels;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const result = new Float32Array(length);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; for (let i = 0; i &lt; input.length; i++) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; for (let channel = 0; channel &lt; numberOfChannels; channel++) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; result[i * numberOfChannels + channel] = input[i];</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; return result;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; arrayBufferToBase64(buffer) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const binary = [];</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const bytes = new Uint8Array(buffer);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; const len = bytes.byteLength;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; for (let i = 0; i &lt; len; i++) {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; binary.push(String.fromCharCode(bytes[i]));</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; return window.btoa(binary.join(&apos;&apos;));</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; },</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><br>&nbsp;<br>&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;/script&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Response Body:&nbsp;გარდაქმნილი ტექსტი</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Error Response: <strong>422 - &nbsp;Not enough seconds&nbsp;</strong>ასეთის არსებობის შემთხვევაში. ამ შემთხვევაში გთხოვთ გადახვიდეთ ბილინგში ტოკენების შესაძენად.</p>
<div style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">
    <ul>
        <li style="font-size: 11pt;"><strong>ტექსტის გახმოვანება და ხმები</strong></li>
    </ul>
</div>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong>&nbsp;</strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><em>ხმები</em></strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">End-point : <u><a href="https://api.viki-ai.ge/public/api/v1/getVoices%0d" style="color: rgb(13, 110, 253);">https://api.viki-ai.ge/public/api/v1/getVoices</a></u></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Method: POST</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Authorization: Bearer Token</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Request Body:&nbsp;lang (ka&nbsp;ან&nbsp;en)</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Response Body: &nbsp;ხმების მასივი</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><em>გახმოვანება</em></strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">End-point : <u><a href="https://api.viki-ai.ge/public/api/v1/speechIt" style="color: rgb(13, 110, 253);">https://api.viki-ai.ge/public/api/v1/speechIt</a></u></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Method: POST</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Authorization: Bearer Token</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Request Body: lang (ka&nbsp;ან&nbsp;en) , text , voiceID</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Response Body : &nbsp;.wav ფაილის binary ; <strong><span style="color: rgb(192, 0, 0);">response type: arrayBuffer</span></strong></p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">Error Response: <strong>422 - &nbsp;Not enough characters.</strong> ასეთის არსებობის შემთხვევაში. ამ შემთხვევაში გთხოვთ გადახვიდეთ ბილინგში ტოკენების შესაძენად.</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">ქვემოთ მოყვანილია&nbsp;VUE.JS-ზე&nbsp;მაგალითი თუ როგორ დავამუშავოთ&nbsp;Response-ი</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">წარმოვიდგინოთ რომ დაგვიბრუნდა პასუხი&nbsp;response.data,&nbsp;მაშინ:</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;template&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &lt;audio &nbsp;ref=&quot;audioPlayer&quot; controls&gt;&lt;/audio&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;/template&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;script&gt;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; export default{</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; methods:{</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; translateToText(){</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; //ასინქრონული მიკითხვის შედეგად&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; someFunc().then(response=&gt;{</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; const blob = new Blob([response.data], { type: &apos;audio/wav&apos; });</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;const url = URL.createObjectURL(blob);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;this.$refs.audioPlayer.src = url;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;this.$refs.audioPlayer.play();</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">}).catch(err=&gt;{</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;const blob = new Blob([err.response.data], { type: &apos;application/json&apos; });</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; blob.text().then((text) =&gt; {</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; const jsonData = JSON.parse(text);</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; console.log(jsonData) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; })</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">}) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp;</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">}</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; }</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">}</p>
<p style="text-align: start;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 11pt;">&lt;/script&gt;</p>


<h4>გისურვებთ წარმატებებს!</h4>
</template>