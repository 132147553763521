<template>
     <div class="container-fluid" style="height: 100vh; overflow: hidden;">
        <div class="row">
            <div class="d-none d-lg-block col-lg-8 p-0">

                <img src="@/assets/images/back.webp" alt="" style="width: 100%; height: 108vh;">

            </div>
            <div class="col-12 col-lg-4">

                <div class="row justify-content-center align-items-center position-relative" style="height: 100vh;">
                    <div class="col-8">
                        <h3 class="text-center m-4">Viki AI</h3>
                        <form>
                            <div class="mb-3">
                                <input type="email" placeholder="მეილი" v-model="email" class="form-control">
                            </div>
                            <div class="mb-3">
                                <input type="password" placeholder="პაროლი" v-model="password" class="form-control">
                            </div>
                            <div class="d-grid">
                                <button :disabled="!email || !password" type="button" @click="login()" class="btn btn-outline-warning">შესვლა</button>
                            </div>
                        </form>
                        <div v-if="error" class="bg-danger bg-gradient m-3 d-flex align-items-center justify-content-center rounded">
                            <p class="text-white mt-2">{{ error.error }}</p>
                        </div>
                        <p style="font-size: 10px;" class="text-center m-2">არ ხარ დარეგისტრირებული? გაიარე <router-link to="/signUp">რეგისტრაცია</router-link></p>  
                    </div>
                    <div class="position-absolute bottom-0 text-center mb-3">&copy; ყველა უფლება დაცულია. 2024. <a href="https://viki-ai.ge">viki-ai.ge</a></div>
                </div>

               
            </div>
        </div>
    </div>
</template>
<script>
import HttpRequests from '@/httpRequests/httpRequests';
const httpRequests=new HttpRequests()
export default{
    data(){
        return{
            email:null,
            password:null,
            error:null
        }
    },
    methods:{
        login(){
            httpRequests.login(this.email,this.password).then(response=>{
                localStorage.setItem('token',response.data.token)
                window.location.href='/'
            }).catch(err=>{
                this.error=err.response.data
            })
        }
    }
}

</script>