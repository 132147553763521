<template>
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-8">
            
            <div class="row">
                <div class="row">
            <!-- Card 1 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 180px !important;">
                        <h5 class="card-title">ნაბიჯი 1</h5>
                        <p class="card-text">აირჩიე ენა</p>
                        

                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio1" value="ka">
                        <label class="form-check-label" for="inlineRadio1">ქართ.</label>
                        </div>
                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio2" value="en">
                        <label class="form-check-label" for="inlineRadio2">ENG.</label>
                        </div>



                    </div>
                </div>
            </div>
            <!-- Card 2 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 180px !important;">
                        <h5 class="card-title">ნაბიჯი 2</h5>
                        <p class="card-text">ატვირთე PDF-ები შესაბამისად შერჩეულ ენაზე</p>
                       
                    </div>
                </div>
            </div>
            <!-- Card 3 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 180px !important;">
                        <h5 class="card-title">ნაბიჯი 3</h5>
                        <p class="card-text">აირჩიე ხმა</p>
                        

                        <div class="form-check form-check-inline" v-for="voice in voices" :key="voice.vocieID"> 
                        <input class="form-check-input" type="radio" name="chosenVoiceID" v-model="chosenVoiceID" id="inlineRadio1" :value="voice.voiceID">
                        <label class="form-check-label" for="inlineRadio1">{{ voice.voice }}</label>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
            </div>


            <div class="d-grid">
                <button class="btn btn-outline-danger" :disabled="chosenVoiceID==0" @click="clickOnGetPdf()">PDF-ების ატვირთვა</button>
                <input type="file" class="d-none" id="getPdf"  @change="getPdf">
            </div>
            <h5 class="text-center m-3" v-if="uploadStatus">{{ uploadStatus }}</h5>
            <div>
               
               
                
           <div v-for="data in Object.keys(speechedPDFs)" class="mt-4" v-if="!pending">
           
            <h4 class="text-center">{{ data.split('_')[0] }}</h4>

            <table  class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="file in speechedPDFs[data]">
                    <td>{{ file }}</td>
                    <td>
                        <i v-if="!download[file]"class="bi bi-download" style="cursor: pointer;" @click="downloadFile(file,data.split('_')[1])"></i>
                        <i v-else class="bi bi-hourglass-split"></i>
                    </td>
                </tr>
            </tbody>
            </table>

           </div>     
           <div v-else class="text-center">
            <img src="../assets/images/loader.gif" class="img-fluid">
           </div>

                 

            
                    
               

                
               
            </div>

        </div>
    </div>
</template>
<script>
    import HttpRequests from '../httpRequests/httpRequests'
    const httpRequests= new HttpRequests()
    const CryptoJS=require('crypto-js')
    import axios from 'axios'


    export default{
        data(){
            return{
                 uploadStatus:null, 
                chosenAudioLang:'ka',
                voices:[],
                chosenVoiceID:0,
                speechedPDFs:{},
                pending:false,
                download:{}
              
            }
        },

        mounted(){

            this.generateHeader().then(()=>{

          
            httpRequests.getVoices(this.chosenAudioLang).then(response=>{
                    this.voices=response.data
            }) 

        })

        this.pending=true
        setTimeout(() => {
            this.generateHeader().then(()=>{
            httpRequests.getSpeechedPDFs().then(response=>{

                 

                this.speechedPDFs=response.data

                this.pending=false

                })
        })
            
        }, 2000);
       

        },
        watch:{
            chosenAudioLang(val){
                this.generateHeader().then(()=>{

               
                httpRequests.getVoices(val).then(response=>{
                    this.voices=response.data
            })
        })
            }
        },
        methods:{

            async  generateHeader(){
             
             return   httpRequests.customMiddlewareTokens().then(response=>{
    
                  
                  axios.defaults.headers.common['X-Trial-Header']=CryptoJS.MD5('YH36uiJvxBfixgUfwuKeyVF2CzEScE'+response.data).toString() 
                    
                      })
                
             
                  },

            clickOnGetPdf(){
                document.getElementById('getPdf').click()
            },
            getPdf(e){
                this.generateHeader().then(()=>{
             let formData=new FormData()
             
               
                formData.append('pdfFiles',e.target.files[0])
                formData.append('lang',this.chosenAudioLang)
                formData.append('voiceID',this.chosenVoiceID)
             
             this.uploadStatus='იტვირთება, გთხოვთ დაელოდოთ'
             httpRequests.uploadPdfForSpeech(formData).then(response=>{
                
                if(response.data.status=='IS.UPLOADED'){
                

                    this.$swal({
                        title: 'აიტვირთა!',
                        text:  'გახმოვანების დაწყება/დასრულების თაობაზე მიიღებთ შეტყობინებას თქვენს მეილზე.',
                        icon: 'success',
                        confirmButtonText: 'კარგი', 

                        }).then(response=>{
                            if(response.isConfirmed){
                                location.reload()
                            }
                        });




                  
                   
                }

             }).catch(err=>{

                this.$swal({
                        title: 'შეცდომა!',
                        text: err.response.data.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });

                 


             })

            })

            },
           
         
            downloadFile(file,id){
                this.download[file]=true
                this.generateHeader().then(()=>{

                    httpRequests.downloadFile(file,id).then(response=>{ 

                            const blob = new Blob([response.data], { type: 'audio/wav' });

                                // Generate a URL for the Blob object
                                const url = window.URL.createObjectURL(blob);

                                // Create a link element
                                const a = document.createElement('a');
                                a.style.display = 'none';
                                a.href = url;
                                a.download = file;  // Specify the file name for the download

                                // Append the link to the document and trigger the download
                                document.body.appendChild(a);
                                a.click();

                                // Clean up and remove the link
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);




                        this.download={}
                    })

                })
            }
            


 




        }

    }

</script>