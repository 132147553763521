<template>
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-8">
            
            
                <div class="row justify-content-center">
            <!-- Card 1 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 150px !important;">
                        
                        <p class="card-text">აირჩიე ენა</p>
                        

                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio1" value="ka">
                        <label class="form-check-label" for="inlineRadio1">ქართ.</label>
                        </div>
                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio2" value="en">
                        <label class="form-check-label" for="inlineRadio2">ENG.</label>
                        </div>



                    </div>
                </div>
            </div>
            
        </div>
            


             
            <div>
               

                <div class="row align-items-center">
                    <div class="col-10">
                        <textarea class="form-control mt-5" style="min-height: 400px;" v-model="answer"></textarea>
                    </div>
                    <div class="col-2">
                        <div v-for="voice in voices" :key="voice.vocieID" class="mb-2">
                            <button type="button" :disabled="!answer" class="btn btn-outline-secondary w-100" @click="speechIt(voice.voiceID)">
                                <i :class="!translatingToText[voice.voiceID]?'bi bi-headset':'bi bi-hourglass-top'"></i>{{ voice.voice }}
                                
                            </button>
                        </div>
                    </div>

                    <audio class="d-none" ref="audioPlayer" controls></audio>

                </div>

                
               
            </div>

        </div>
    </div>
</template>
<script>
    import HttpRequests from '../httpRequests/httpRequests'
    const httpRequests= new HttpRequests()
      const CryptoJS=require('crypto-js')
    import axios from 'axios'
    export default{
        data(){
            return{
               
                 answer:null,
             
                audioContext: null,
                mediaStream: null,
                mediaRecorder: null,
                audioChunks: [],
                recordedBlob: null,
                chosenAudioLang:'ka',
                voices:[],
                translatingToText:{}
            }
        },

        mounted(){
            this.generateHeader().then(()=>{

           
            httpRequests.getVoices(this.chosenAudioLang).then(response=>{
                    this.voices=response.data
            })
        })

        },
        watch:{
            chosenAudioLang(val){
                this.generateHeader().then(()=>{

               
                httpRequests.getVoices(val).then(response=>{
                    this.voices=response.data
            })
        })
            }
        },
        methods:{
            async  generateHeader(){
             
             return   httpRequests.customMiddlewareTokens().then(response=>{
    
                  
                  axios.defaults.headers.common['X-Trial-Header']=CryptoJS.MD5('YH36uiJvxBfixgUfwuKeyVF2CzEScE'+response.data).toString() 
                    
                      })
                
             
                  },
           

    speechIt(vocieID){

        this.generateHeader().then(()=>{

       

        this.translatingToText[vocieID]=true

        httpRequests.speechIt(vocieID,this.chosenAudioLang,this.answer).then(response=>{

            this.translatingToText={}

            const blob = new Blob([response.data], { type: 'audio/wav' });
            const url = URL.createObjectURL(blob);
            this.$refs.audioPlayer.src = url;
            this.$refs.audioPlayer.play();
 
        }).catch(err=>{
            this.translatingToText={}
            const blob = new Blob([err.response.data], { type: 'application/json' });

            blob.text().then((text) => {
                const jsonData = JSON.parse(text);
               

                this.$swal({
                        title: 'შეცდომა!',
                        text: jsonData.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });
            })
 
           
        })
    })
    }




        }

    }

</script>