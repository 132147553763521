<template>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th>სერვისი</th>
                <th>აღწერა</th>
                <th>მინიმუმ შესყიდვა</th>
                <th>მაქსიმუმ შესყიდვა</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="service in serviceInfo" :key="service.serviceID">
                <td>{{ service.service }}</td>
                <td>{{ service.description }}</td>
                <td>{{ service.minimum }}</td>
                <td>{{ service.maximum }}</td>
                <td><button type="button"  data-bs-toggle="modal" data-bs-target="#addToBilling" class="btn btn-outline-success" @click="addToBilling(service)">ყიდვა</button></td>
            </tr>
        </tbody>
    </table>

    <div class="container">
        <table class="table" v-if="cart.length>0">
            <thead>
                <tr>
                    <th>სერვისი</th>
                    <th>რაოდენობა</th>
                    <th>ფასი</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in cart" :key="item.serviceID">
                    <td>{{ item.service }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.price }}$</td>
                    <td>
                        <button type="button" class="btn btn-outline-danger" @click="deleteFromCart(item.serviceID)">წაშლა</button>
                    </td>
                     
                </tr>
                <tr>
                    <td colspan="4" align="right">
                        <button class="btn btn-outline-primary" type="button" @click="buyItems()">ყიდვა - {{ sumPrice.toFixed(2) }}$</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="remainItems.length>0">
            <h3 class="text-center">შეძენილი სერვისები</h3>

            <table class="table">
                <thead>
                    <tr>
                        <th>სერვისი</th>
                        <th>რაოდენობა</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="remainItem in remainItems" :key="remainItem.userServiceID">
                        <td>{{ remainItem.services[0].service }}</td>
                        <td>{{ remainItem.counts }}</td>
                    </tr>
                </tbody>
            </table>

        </div>


    </div>

    <div class="modal fade" id="addToBilling" tabindex="-1" aria-labelledby="addToBillingLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ chosenService?.service }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="cancelOrder()" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <p class="text-center fw-bold">{{ chosenService?.description }}</p>

        <div class="row justify-content-center align-items-center">
            <div class="col-2">
                <button class="btn btn-outline-danger" @click="decreaseOrder(chosenService)">-</button>
            </div>
            <div class="col-2">
                
                {{ amount }}
            </div>
            <div class="col-2">
                <button class="btn btn-outline-success" @click="increaseOrder(chosenService)">+</button>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="cancelOrder()" data-bs-dismiss="modal">გაუქმება</button>
        <button type="button" class="btn btn-primary" @click="addToCart(chosenService)">დამატება</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import HttpRequests from '../httpRequests/httpRequests'
const httpRequests = new HttpRequests()

export default{
    data(){
        return{
            serviceInfo:[],
            chosenService:null,
            amount:0,
            cart:[],
            remainItems:[],
            sumPrice:0
        }
    },
    mounted(){
        httpRequests.getServices().then(response=>{
            this.serviceInfo=response.data
        })

        httpRequests.getRemainItems().then(response=>{
            this.remainItems=response.data
        })
    },
    watch:{
        chosenService(val){
            if(val){
                this.amount=val.minimum
            }

        }
    },
    methods:{
        addToBilling(service){
            this.chosenService=service
        },
        cancelOrder(){
            this.chosenService=null
        },
        decreaseOrder(service){

            if(this.amount-service.minimum<service.minimum){
                this.$swal({
                        title: 'შეცდომა!',
                        text: 'მინიმალური რაოდენობა არის : '+service.minimum,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });
                this.amount=service.minimum
            }else{
                this.amount=this.amount-service.minimum
            }


        },
        increaseOrder(service){

            if(this.amount+service.minimum>service.maximum){
                this.$swal({
                        title: 'შეცდომა!',
                        text: 'მაქსიმალური რაოდენობა არის : '+service.maximum,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });
                this.amount=service.maximum
            }else{
                this.amount=this.amount+service.minimum
            }


        },
        addToCart(service){

            if(this.cart.find(o=>o.serviceID==service.serviceID)){

                this.$swal({
                        title: 'შეცდომა!',
                        text: 'სერვისი უკვე დამატებულია! წაშალეთ ძველი რათა დაამატოთ ახალი რაოდენობით',
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });

            }else{
 
                if(service.serviceID==2){
                    var devider=60
                }else if(service.serviceID==5){
                    var devider=2000000
                }
                else{
                    var devider=1000000
                }
            
            this.cart.push({
                serviceID:service.serviceID,
                service:service.service,
                amount:this.amount,
                price:service.price*(this.amount/devider)
            })

            this.sumPrice+=service.price*(this.amount/devider)

        }

        },
        deleteFromCart(serviceID){
            this.sumPrice=this.sumPrice-this.cart.find(o=>o.serviceID==serviceID).price

            this.cart.splice(this.cart.findIndex(o=>o.serviceID==serviceID),1)

            

          
        },
        buyItems(){
            httpRequests.buyItems(this.cart).then(response=>{

                

                this.$swal({
                        title: 'გილოცავთ!',
                        text: 'თქვენ წარმატებით შეიძინიეთ სერვისები '+JSON.stringify(response.data),
                        icon: 'success',
                        confirmButtonText: 'კარგი'
                        });

            }).catch(err=>{

                this.$swal({
                        title: 'შეცდომა',
                        text: err.response.data.error,
                        icon: 'error',
                        confirmButtonText: 'კარგი'
                        });

            })
        }
    }
}

</script>