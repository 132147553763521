<template>
      <div class="row justify-content-around align-items-center" style="height: 100vh">
                <div class="col-12 col-md-6 col-lg-3 m-2 bg-light bg-gradient shadow rounded p-3">
                    <p class="text-secondary text-center">
                        Speech To Text
                    </p>
                    
                        <ul class="text-secondary">
                            <li>გარდაქმენი შენი ხმა ტექსტად</li>
                            <li>ქართული და ინგლისური ენის მხარდაჭერა</li>
                            <li>საცდელი 60 წამი</li>
                        </ul>
                    
                </div>
                <div class="col-12 col-md-6 col-lg-3 m-2  bg-light bg-gradient shadow rounded p-3">
                    <p class="text-secondary text-center">
                        Text To Speech
                    </p>
                    <ul class="text-secondary">
                        <li>გაახმოვანე შენი ტექსტი</li>
                        <li>ქართული და ინგლისური ენის მხარდაჭერა</li>
                        <li>საცდელი 500 სიმბოლო</li>
                    </ul>
                </div>
                
                <div class="col-12 col-md-6 col-lg-3 m-2  bg-light bg-gradient shadow rounded p-3">
                    <p class="text-secondary text-center">
                        Viki AI
                    </p>
                    <ul class="text-secondary">
                        <li>ატვირთე შენი .pdf ფაილები და ვიკი ისწავლის მათ</li>
                        <li>ქართული და ინგლისური ენის მხარდაჭერა</li>
                        <li>დაუსვი ნებისმიერი კითხვა ნასწავლი დოკუმენტებიდან და მიიღე რელევანტური და ლაკონური პასუხი</li>
                        <li>საცდელი 500 ტოკენი<sup>1</sup> დატრენინგებისთვის</li>
                        <li>საცდელი 1000 ტოკენი<sup>1</sup> კითხვის დასმა+პასუხის მიღება</li>
                    </ul>
                    <small class="text-muted">1. <u>ტოკენი</u> - სიტყვები, პუნქტუაციის სიმბოლოები. (ყველაფერი ჰარის,space-ის გარდა)</small>
                </div>
            </div>
</template>