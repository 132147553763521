import axios from "axios";

export default class HttpRequests{

    login(email,password){

        return axios.post('login',{email,password})

    }

    logout(){ 
       return axios.post('logout')  
    }

  



    getInfoOnRefresh(){

     return axios.get('/getInfoOnRefresh')

    }

    getServices(){
        return axios.get('/getServices')
    }

    buyItems(cart){
        return axios.post('/buyItems',{cart})
    }

    getRemainItems(){
        return axios.get('/getRemainItems')
    }

    uploadPdf(formData){

        return axios.post('/uploadPdf',formData)

    }

    uploadPdfForSpeech(formData){

        return axios.post('/uploadPdfForSpeech',formData)

    }

    getSpeechedPDFs(){
        return axios.get('/getSpeechedPDFs')
    }

    downloadFile(file,id){

        return axios.post('/downloadFile',{file,id},{responseType:'blob'})
    }

     

    sendQuestion(question,lang){
        return axios.post('/sendQuestion',{question,lang})
    }
    convertSpeechToText(base64String,lang){

        return axios.post('/convertSpeechToText',{base64String,lang})

    }
    getVoices(lang){
        return  axios.post('/getVoices',{lang})
    }

    speechIt(voiceID,lang,text){
        return axios.post('/speechIt',{voiceID,lang,text},{responseType:'arraybuffer'})
    }

    getOneTimeCode(email){

        return axios.post('/getOneTimeCode',{email})

    }

    saveUser(fname,lname,email,password,confirmPassword,isOrganization,
        orgName,orgRegCode,oneTimeCode){
        return axios.post('/saveUser',{fname,lname,email,password,confirmPassword,isOrganization,
            orgName,orgRegCode,oneTimeCode})
    }

    customMiddlewareTokens(){
        return axios.get('/customMiddlewareTokens')
    }

}