<template>
        <nav class="navbar navbar-expand-md navbar-light bg-light sticky-top">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">Viki AI</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link active" aria-current="page" to="/">მთავარი</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/api">API დოკუმენტაცია</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/billing">ბილინგი</router-link>
              </li>
            
              
              <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            სერვისები
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link class="dropdown-item" to="/tryViki">ვიკის დატრენინგება</router-link></li>
            <li><router-link class="dropdown-item" to="/speechToText">აუდიო-ტექსტი</router-link></li> 
            <li><router-link class="dropdown-item" to="/textToSpeech">ტექსტი-აუდიო</router-link></li>
            <li><router-link class="dropdown-item" to="/pdftospeech">PDF2Speech</router-link></li>
          </ul>
        </li>
              
            </ul>
           <div><button type="button" @click="logout()" role="button" class="btn btn-outline-danger">გასვლა</button></div>
          </div>
        </div>
      </nav>

      <div class="container-fluid">
            <router-view/>
        </div>
</template>
<script>
import HttpRequests from '../httpRequests/httpRequests'
const httpRequests = new HttpRequests()

export default{
    methods:{
        logout(){
            httpRequests.logout().then(()=>{
                 localStorage.removeItem('token')
                window.location.href='/'
            })
        }
    }
}

</script>