<template>
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-8">
            
         
                <div class="row justify-content-center">
            <!-- Card 1 -->
            <div class="col-md-4 mb-4">
                <div class="card"> 
                    <div class="card-body" style="height: 150px !important;">
                       
                        <p class="card-text">აირჩიე ენა</p>
                        

                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio1" value="ka">
                        <label class="form-check-label" for="inlineRadio1">ქართ.</label>
                        </div>
                        <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="chosenAudioLang" id="inlineRadio2" value="en">
                        <label class="form-check-label" for="inlineRadio2">ENG.</label>
                        </div>



                    </div>
                </div>
            </div>
          
        </div>
            


           
            <div> 

                <div class="row align-items-center">
                    <div class="col-11">
                        <textarea readonly    class="form-control" style="width: 100%; height: 200px;">{{ question }}</textarea>
                      
                    </div>
                    <div class="col-1">
                        <button v-if="!isRecording" class="btn btn-outline-secondary" type="button" @click="startRecording()">
                            <i class="bi bi-mic-mute-fill"></i>
                        </button>
                        
                            <button v-else class="btn btn-outline-secondary" type="button" @click="stopRecording()">
                                <i class="bi bi-mic-fill"></i>
                          </button>
                       
                         
                      

                    </div>
                </div>
               

              

                 

                 

                
               
            </div>

        </div>
    </div>
</template>
<script>
    import HttpRequests from '../httpRequests/httpRequests'
    const httpRequests= new HttpRequests()
      const CryptoJS=require('crypto-js')
    import axios from 'axios'
    export default{
        data(){
            return{
               
                 question:null, 
                isRecording: false,
                audioContext: null,
                mediaStream: null,
                mediaRecorder: null,
                audioChunks: [],
                recordedBlob: null,
                chosenAudioLang:'ka',
                
              
            }
        },
        
    
        methods:{
        
        async  generateHeader(){
             
         return   httpRequests.customMiddlewareTokens().then(response=>{

              
              axios.defaults.headers.common['X-Trial-Header']=CryptoJS.MD5('YH36uiJvxBfixgUfwuKeyVF2CzEScE'+response.data).toString() 
                
                  })
            
         
              },



            async startRecording() {
      this.isRecording = true;
      this.question='ვიკი გისმენს...'

      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const source = this.audioContext.createMediaStreamSource(this.mediaStream);

      // Create a mono merger node
      const merger = this.audioContext.createChannelMerger(1);
      source.connect(merger, 0, 0);

      const monoStream = this.audioContext.createMediaStreamDestination();
      merger.connect(monoStream);

      this.mediaRecorder = new MediaRecorder(monoStream.stream);

      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        const wavBuffer = this.encodeWAV(audioBuffer);
        this.recordedBlob = new Blob([wavBuffer], { type: 'audio/wav' });

        // Do something with the binary data
        const reader = new FileReader();
        reader.readAsArrayBuffer(this.recordedBlob);
        reader.onloadend = () => {
          const arrayBuffer = reader.result;
          // Here you can use arrayBuffer which is a binary representation of the .wav file
               
          const base64String = this.arrayBufferToBase64(arrayBuffer);

          this.generateHeader().then(()=>{

        
            httpRequests.convertSpeechToText(base64String,this.chosenAudioLang).then(response=>{
                this.question=response.data
            })
          })
           
        };
      };

      this.audioChunks = [];
      this.mediaRecorder.start();
    },
    stopRecording() {
       
      this.isRecording = false;
      this.question='ვიკი აანალიზებს, გთხოვ დაელოდო...'
      this.mediaRecorder.stop();
      this.mediaStream.getTracks().forEach(track => track.stop());
    },
    encodeWAV(audioBuffer) {
      const numberOfChannels = 1;
      const sampleRate = audioBuffer.sampleRate;
      const format = 1; // PCM
      const bitDepth = 16;

      const result = this.interleave(audioBuffer.getChannelData(0), numberOfChannels);

      const byteRate = sampleRate * numberOfChannels * (bitDepth / 8);
      const blockAlign = numberOfChannels * (bitDepth / 8);

      const buffer = new ArrayBuffer(44 + result.length * 2);
      const view = new DataView(buffer);

      /* RIFF identifier */
      this.writeString(view, 0, 'RIFF');
      /* file length */
      view.setUint32(4, 36 + result.length * 2, true);
      /* RIFF type */
      this.writeString(view, 8, 'WAVE');
      /* format chunk identifier */
      this.writeString(view, 12, 'fmt ');
      /* format chunk length */
      view.setUint32(16, 16, true);
      /* sample format (raw) */
      view.setUint16(20, format, true);
      /* channel count */
      view.setUint16(22, numberOfChannels, true);
      /* sample rate */
      view.setUint32(24, sampleRate, true);
      /* byte rate (sample rate * block align) */
      view.setUint32(28, byteRate, true);
      /* block align (channel count * bytes per sample) */
      view.setUint16(32, blockAlign, true);
      /* bits per sample */
      view.setUint16(34, bitDepth, true);
      /* data chunk identifier */
      this.writeString(view, 36, 'data');
      /* data chunk length */
      view.setUint32(40, result.length * 2, true);

      let offset = 44;
      for (let i = 0; i < result.length; i++, offset += 2) {
        view.setInt16(offset, result[i] * 0x7FFF, true);
      }

      return buffer;
    },
    writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    interleave(input, numberOfChannels) {
      const length = input.length * numberOfChannels;
      const result = new Float32Array(length);

      for (let i = 0; i < input.length; i++) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
          result[i * numberOfChannels + channel] = input[i];
        }
      }

      return result;
    },
  
    arrayBufferToBase64(buffer) {
      const binary = [];
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary.push(String.fromCharCode(bytes[i]));
      }
      return window.btoa(binary.join(''));
    },


    




        }

    }

</script>